import { Provider } from '@angular/core';
import { forceBackToCurrentLocation } from '@bcf-v2-configurators/tokens/cashier/mobile-web-twa/force-back-to-current-location';
import { FORCE_BACK_TO_CURRENT_LOCATION } from '@bcf-v2-configurators/tokens/cashier/token';
import { cookieConsentDisabled } from '@bcf-v2-configurators/tokens/cookie-consent/projects/sportsbook/letsbetmd/mobile-twa-cookie-consent-disabled';
import { COOKIE_CONSENT_DISABLED } from '@bcf-v2-configurators/tokens/cookie-consent/token';

export const settingsProviders: Provider[] = [
  {
    provide: COOKIE_CONSENT_DISABLED,
    useValue: cookieConsentDisabled
  },
  {
    provide: FORCE_BACK_TO_CURRENT_LOCATION,
    useValue: forceBackToCurrentLocation
  }
];
